const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	filtro: {
		ano: new Date().getFullYear(),
		mes: (() => {
			let data = new Date();
			data.setMonth(data.getMonth() - 1);

			return data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
		})(),
		id_cliente: null,
		id_status: null,
		id_tipo: null,
		id_parceiro: null,
		data_inicial: null,
		data_final: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'RELATORIO_FINANCEIRO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'RELATORIO_FINANCEIRO_LISTADO':
			console.log(action.payload.data)
			return {
				...state,
				lista: action.payload.data,

			};

		case 'RELATORIO_FINANCEIRO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'RELATORIO_FINANCEIRO_FILTRO':
			return { ...state, filtro: action.payload };


		case 'RELATORIO_FINANCEIRO_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
			};

		case 'RELATORIO_FINANCEIRO_PARCEIRO_SELECT_LISTADO':
			return {
				...state,
				listaParceiro: action.payload.data
			};

		case 'PROCESSO_ITEM_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};
		default:
			return state;
	}
}
