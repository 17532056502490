import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PROCESSO_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				preco_processo: FormatUtils.formatarValorTela(registro.preco_processo, 2),
				valor_cobrado: FormatUtils.formatarValorTela(registro.valor_cobrado, 2),

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('processoItemForm', {
			...registro,
			preco_processo: FormatUtils.formatarValorTela(registro.preco_processo, 2),
			valor_cobrado: FormatUtils.formatarValorTela(registro.valor_cobrado, 2),
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'PROCESSO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(idProcesso) {
    const request = axios.get(`${consts.API_URL}/processoItem?id_processo=${idProcesso}`);
    return {
        type: 'PROCESSO_ITEM_LISTADO',
        payload: request
    };
}

export function getListaHistorico(idProcesso) {
    const request = axios.get(`${consts.API_URL}/processoItem/historico?id_processo=${idProcesso}`);
    return {
        type: 'PROCESSO_ITEM_HISTORICO_LISTADO',
        payload: request
    };
}

export function getListaProcesso() {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/processo?
			id_cliente=${getState().processo.filtro.id_cliente || null}
			&ano=${getState().processo.filtro.ano}
			&mes=${getState().processo.filtro.mes}`)
		.then(resp => {
			dispatch({
				type: 'PROCESSO_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/processoItem`, {
				...registro,
				preco_processo: FormatUtils.formatarValorBanco(registro.preco_processo, 2),
				valor_cobrado: FormatUtils.formatarValorBanco(registro.valor_cobrado, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
			
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_processo));
				dispatch(getListaHistorico(registro.id_processo));
				dispatch(getListaProcesso());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/processoItem`, {
				...registro,
				preco_processo: FormatUtils.formatarValorBanco(registro.preco_processo, 2),
				valor_cobrado: FormatUtils.formatarValorBanco(registro.valor_cobrado, 2),
				id_usuario_alteracao: getState().auth.usuario.id,
				
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_processo));
				dispatch(getListaHistorico(registro.id_processo));
				dispatch(getListaProcesso());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/processoItem?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_processo));
			dispatch(getListaHistorico(registro.id_processo));
			dispatch(getListaProcesso());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/processoTipo/listarSelect`);
    return {
        type: 'PROCESSO_ITEM_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaStatus() {
    const request = axios.get(`${consts.API_URL}/processoStatus/listarSelect`);
    return {
        type: 'PROCESSO_ITEM_STATUS_SELECT_LISTADO',
        payload: request
    };
}
