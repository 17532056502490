import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import DateFormat from '../common/dateFormat/DateFormat';

import {
	setModoTela, initForm
} from './clienteActions';

class ClienteForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='cpf'
								component={LabelAndInputMask}
								label='CPF *' placeholder='Informe o CPF'
								cols='12 6 6 6'
								mask='999.999.999-99'
								readOnly={readOnly} />
							
							<Field
								name='telefone'
								component={LabelAndInputMask}
								label='Telefone *' placeholder='Informe o telefone'
								cols='12 6 6 6'
								mask='(99) 9 9999-9999'
								readOnly={readOnly} />

						</Row>
						<Row>

							<Field
								name='rg'
								component={LabelAndInputMask}
								label='RG' placeholder='Informe o RG'
								cols='12 6 6 6'
								mask='9999999999'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='pessoa_juridica'
								component={LabelAndCheckbox}
								label='Pessoa Jurídica' placeholder='Marque se for pessoa jurídica'
								cols='12 4 3 2'
								readOnly={readOnly} />

							<Field
								name='cnpj'
								component={LabelAndInputMask}
								label='CNPJ' placeholder='Informe o CNPJ'
								cols='12 6 6 6'
								mask='99.999.999/9999-99'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço' placeholder='Informe o endereço'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Select
								name='id_estado'
								options={this.props.listaEstado}
								label='Estado'
								cols='12 6 6 6'
								placeholder='Selecione o estado'
								readOnly={readOnly}
								value={this.props.formularioValues.id_estado}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										id_estado: data
									})
								}} />

							<Select
								name='id_cidade'
								options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado)}
								label='Cidade'
								cols='12 6 6 6'
								placeholder='Selecione a cidade'
								readOnly={readOnly}
								value={this.props.formularioValues.id_cidade}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										id_cidade: data
									})
								}} />
						</Row>

						<Row>
							<Field
								name='cep'
								component={LabelAndInputMask}
								label='CEP *' placeholder='Informe o CEP'
								cols='12 6 5 4'
								mask='99999-999'
								readOnly={readOnly} />



							<Field
								name='email'
								component={LabelAndInput}
								label='Email' placeholder='Informe o email'
								cols='12 6 5 4'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='usuario_gov_br'
								component={LabelAndInput}
								label='Usuário (Gov BR)' placeholder='Informe o usuário'
								cols='12 6 6 6'
								readOnly={readOnly} />

							<Field
								name='senha_gov_br'
								component={LabelAndInput}
								label='Senha (Gov BR)' placeholder='Informe a senha'
								cols='12 6 6 6'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='cr'
								component={LabelAndInput}
								label='CR' placeholder='Informe o CR'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<LabelAndInputDate
								name='data_expedicao_cr'
								label='Data de Expedição do CR' placeholder='Informe a data de expedição'
								cols='12 6 4 3'
								readOnly={readOnly}
								value={this.props.formularioValues.data_expedicao_cr}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										data_expedicao_cr: data.target.value
									});
								}} />

							{this.props.formularioValues.dias_expirar_cr == null ? null : (
								<Grid cols='12 6 4 6'>
									<div
										class={`bg-${this.props.formularioValues.dias_expirar_cr > 180 ? 'primary' : (this.props.formularioValues.dias_expirar_cr > 90 ? 'warning' : 'danger')} color-palette`}
										style={{ padding: 8 }}>
										<label>Validade: {DateFormat.formatarDataSqlParaTela(this.props.formularioValues.data_validade_cr)}</label>
										<br />
										CR vence em {this.props.formularioValues.dias_expirar_cr} {this.props.formularioValues.dias_expirar_cr == 1 ? 'dia' : 'dias'}
									</div>
								</Grid>
							)}
							<LabelAndInputDate
								name='data_expedicao_gt'
								label='Data de Expedição da GT' placeholder='Informe a data de expedição'
								cols='12 6 4 4'
								readOnly={readOnly}
								value={this.props.formularioValues.data_expedicao_gt}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										data_expedicao_gt: data.target.value
									});
								}} />

							{this.props.formularioValues.dias_expirar_gt == null ? null : (
								<Grid cols='12 6 8 8'>
									<div
										class={`bg-${this.props.formularioValues.dias_expirar_gt > 180 ? 'primary' : (this.props.formularioValues.dias_expirar_gt > 90 ? 'warning' : 'danger')} color-palette`}
										style={{ padding: 8 }}>
										<label>Validade: {DateFormat.formatarDataSqlParaTela(this.props.formularioValues.data_validade_gt)}</label>
										<br />
										GT vence em {this.props.formularioValues.dias_expirar_gt} {this.props.formularioValues.dias_expirar_gt == 1 ? 'dia' : 'dias'}
									</div>
								</Grid>
							)}
						</Row>

					

						<Row>
							<Field
								name='ocupacao'
								component={LabelAndInput}
								label='Ocupação' placeholder='Informe a ocupação'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='id_clube'
								component={Select}
								options={this.props.listaClube}
								label='Clube'
								cols='12 6 6 6'
								placeholder='Selecione o clube'
								readOnly={readOnly} />

							<Field
								name='clube_vinculacao'
								component={LabelAndInput}
								label='Clube de Vinculação' placeholder='Informe o clube de vinculação'
								cols='12 6 6 6'
								readOnly={readOnly} />

							<Field
								name='id_unidade_vinculacao'
								component={Select}
								options={this.props.listaUnidadeVinculacao}
								label='Unidade de Vinculação'
								cols='12 6 6 6'
								placeholder='Selecione a unidade'
								readOnly={readOnly} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

ClienteForm = reduxForm({form: 'clienteForm', destroyOnUnmount: false})(ClienteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('clienteForm')(state),
	registro: state.cliente.registro,
	listaEstado: state.cliente.listaEstado,
	listaCidade: state.cliente.listaCidade,
	listaClube: state.cliente.listaClube,
	listaUnidadeVinculacao: state.cliente.listaUnidadeVinculacao
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
