import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';



export function setAguardando(aguardando) {
    return {
        type: 'RELATORIO_FINANCEIRO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    return (dispatch, getState) => {

		dispatch({
			type: 'RELATORIO_FINANCEIRO_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/processoFinanceiro/listarResultadoPorPeriodo?
			id_cliente=${getState().relatorioFinanceiro.filtro.id_cliente || null}
			&id_processo=${getState().relatorioFinanceiro.filtro.id_proceso || null}
			&id_parceiro=${getState().relatorioFinanceiro.filtro.id_parceiro }
            &data_inicial=${getState().relatorioFinanceiro.filtro.data_inicial }
            &data_final=${getState().relatorioFinanceiro.filtro.data_final }`)
		.then(resp => {
			dispatch({
				type: 'RELATORIO_FINANCEIRO_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function setFiltro(filtro) {
    return {
        type: 'RELATORIO_FINANCEIRO_FILTRO',
        payload: filtro
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'RELATORIO_FINANCEIRO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaParceiro() {
    const request = axios.get(`${consts.API_URL}/parceiro/listarSelect`);
    return {
        type: 'RELATORIO_FINANCEIRO_PARCEIRO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/processoTipo/listarSelect`);
    return {
        type: 'PROCESSO_ITEM_TIPO_SELECT_LISTADO',
        payload: request
    };
}