const INITIAL_STATE = { lista: [],
    listaStatusIcon: [
		{ id: '6', icon: 'far fa-clock' }, { id: '12', icon: 'far fa-file-alt' }, { id: '4', icon: 'fas fa-calendar-check' }, { id: '11', icon: 'fas fa-ban' },
		{ id: '1', icon: 'fas fa-angle-double-right' }, { id: '7', icon: 'fas fa-hourglass-half' }, { id: '9', icon: 'fas fa-file-import' }, { id: '10', icon: 'fas fa-file-import' },
		{ id: '5', icon: 'fas fa-file-import' }, { id: '13', icon: 'fas fa-people-carry' }, { id: '15', icon: 'fas fa-exchange-alt' }
	],
	filtro: []

};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
		case 'DASHBOARD_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
        
        case 'DASHBOARD_FILTRO':
            return { ...state, filtro: action.payload };

        default:
            return state;
    }
}
