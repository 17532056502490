import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './clienteForm';
import Select from '../common/form/select';
import Documento from './clienteDocumento';
import DocumentoForm from './clienteDocumentoForm';
import imagemSoLogo from '../assets/images/sologo.png';
import FormatUtils from '../common/formatUtils/FormatUtils';
import jsPDF from "jspdf";
import "jspdf-autotable";


import {
	setModoTela, initForm, salvar, excluir, getLista, getListaEstado, getListaCidade, getListaClube, getListaUnidadeVinculacao,
	setFiltro, initFormDocumento, salvarDocumento, excluirDocumento
} from './clienteActions';

class Cliente extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaClube();
		this.props.getListaUnidadeVinculacao();
    }

    render() {
        return (
            <div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: '50%', marginRight: '50%' }}>
									<img src={imagemSoLogo} style={{ height: 40, width: 40, marginBottom: -76, marginLeft: 8 }} />
								</div>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}

					{this.props.modoTela == 'cadastroDocumento' ? (
						<DocumentoForm onSubmit={this.props.salvarDocumento} />
					) : null}
					{this.props.modoTela == 'exclusaoDocumento' ? (
						<DocumentoForm excluir onSubmit={this.props.excluirDocumento} />
					) : null}
					{this.props.modoTela == 'listaDocumento' ? (
						<Documento />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
								<Button
										type={'danger'}
										icon={''}
										text='Gerar PDF'
										style={{ width: 192, height: 38 }}
										event={() => {
											this.gerarPDFCliente();
										}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				{this.props.lista.map(item => {
					console.log(this.props.lista);
					let mensagem = null;
					if (item.dias_expirar_cr != null && item.dias_expirar_cr < 90 && item.dias_expirar_gt != null && item.dias_expirar_gt < 90) {
						mensagem = `- CR vence em ${item.dias_expirar_cr} ${item.dias_expirar_cr == 1 ? 'dia' : 'dias'} \n\r
						e GT vence em ${item.dias_expirar_gt} ${item.dias_expirar_gt == 1 ? 'dia' : 'dias'}`;
					} else if (item.dias_expirar_cr != null && item.dias_expirar_cr < 90) {
						mensagem = `- CR vence em ${item.dias_expirar_cr} ${item.dias_expirar_cr == 1 ? 'dia' : 'dias'}`
					} else if (item.dias_expirar_gt != null && item.dias_expirar_gt < 90) {
						mensagem = `- GT vence em ${item.dias_expirar_gt} ${item.dias_expirar_gt == 1 ? 'dia' : 'dias'}`;
					}

					return !mensagem ? null : (
						<div class='alert alert-danger alert-dismissible'>
							<h5><i class='icon fas fa-exclamation-triangle'></i> {item.nome}</h5>
							{mensagem}
						</div>
					);
				})}
				<ContentCardBody>
					<Row>
						<Select
							name='id_clube'
							options={this.props.listaClube}
							label='Clube'
							cols='12 6 6 6'
							placeholder='Selecione o Clube'
							value={this.props.filtro.id_clube}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_clube: value
								});
								this.props.getLista();
							}} />

						<Select
							name='id_unidade_vinculacao'
							options={this.props.listaUnidadeVinculacao}
							label='Unidade de Vinculação'
							cols='12 6 6 6'
							placeholder='Selecione o Unidade de Vinculação'
							value={this.props.filtro.id_unidade_vinculacao}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_unidade_vinculacao: value
								});
								this.props.getLista();
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th>CPF</Th>
								<Th>RG</Th>
								<Th>Cidade</Th>
								<Th>Telefone</Th>
								<Th>Clube</Th>
								<Th>Unidade Vinculação</Th>
								<Th alignCenter> Ultima Unidade Vinculação</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
						{(this.props.lista).filter(item => {
							if (this.state.pesquisar
								&& !(`${item.nome} ${FormatUtils.formatarCPF(item.cpf)} ${item.cpf || ''} ${item.rg || ''} ${item.nome_cidade || ''}
									${FormatUtils.formatarTelefone(item.telefone)} ${item.telefone} ${item.nome_clube} ${item.nome_unidade_vinculacao} ${item.ultima_unidade_vinculacao }
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
								return false;
							}
							return true;
						}).map((item, i) => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td>{FormatUtils.formatarCPF(item.cpf)}</Td>
									<Td>{item.rg || ''}</Td>
									<Td>{item.nome_cidade || ''}</Td>
									<Td>{FormatUtils.formatarTelefone(item.telefone)}</Td>
									<Td>{item.nome_clube || ''}</Td>
									<Td>{item.nome_unidade_vinculacao || ''}</Td>
									<Td>{item.ultima_unidade_vinculacao || ''}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-paperclip'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('listaDocumento', {
													...item
												});
											}} />
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


	gerarPDFCliente() {

		const doc = new jsPDF({
			orientation: 'landscape',
		});

		let colunas = ['Nome', 'CPF', 'RG', 'Cidade', 'Telefone', 'Clube', 'Unidadde Vinculação', 'Ultima Unidade Vinculação'];

		let dados = [];
		this.props.lista.forEach(item => {	
				dados.push([
					item.nome,
					FormatUtils.formatarCPF(item.cpf),
					item.rg,
					item.nome_cidade,
					FormatUtils.formatarTelefone(item.telefone),
					item.nome_clube,
					item.nome_unidade_vinculacao,
					item.ultima_unidade_vinculacao,
				]);
			
		});

		doc.autoTable(colunas, dados, { startY: 20,headerStyles: { 
			halign: 'center', 
			
		} });

		doc.save(`cliente.pdf`);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.cliente.modoTela,
	filtro: state.cliente.filtro,
	lista: state.cliente.lista,
	listaClube: state.cliente.listaClube,
	listaUnidadeVinculacao: state.cliente.listaUnidadeVinculacao,
	aguardando: state.cliente.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getLista, getListaEstado, getListaCidade, getListaClube, getListaUnidadeVinculacao,
	setFiltro, initFormDocumento, salvarDocumento, excluirDocumento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Cliente);
