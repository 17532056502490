import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function getLista() {
    const request = axios.get(`${consts.API_URL}/processo/listarPorStatus`);
    return {
        type: 'DASHBOARD_LISTADO',
        payload: request
    };
   
}
