import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initFormDocumento
} from './processoActions';

class ProcessoDocumentoForm extends Component {

	state = {

	}

    componentWillMount() {
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<label htmlFor='arquivo'>Arquivo</label>
								{this.props.formularioValues && this.props.formularioValues.arquivo ? (
									<p>{this.props.formularioValues.fileName}</p>
								) : (
									<div className='custom-file'>
										<input
											className='custom-file-input'
											type='file'
											id='customFile'
											onChange={event => {
												event.preventDefault();
												let reader = new FileReader();
												let file = event.target.files[0];

												// if (!file.type.includes('application/pdf')) {
												// 	//toastr.error('Erro', 'Selecione apenas PDF.');
												// 	return;
												// }

												reader.onloadend = () => {

													this.props.initFormDocumento({
														...this.props.formularioValues,
														arquivo: reader.result,
														fileName: file.name
													});

												}

												reader.readAsDataURL(file);

											}} />
										<label className='custom-file-label'
											for='customFile'>Selecione o arquivo</label>
									</div>
								)}
							</Grid>
						</Row>

						<br />

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('listaDocumento', this.props.registro)} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }

}

ProcessoDocumentoForm = reduxForm({form: 'processoDocumentoForm', destroyOnUnmount: false})(ProcessoDocumentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('processoDocumentoForm')(state),
	registro: state.processo.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initFormDocumento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoDocumentoForm);
