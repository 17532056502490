const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaSimNao: [{
		id: 1,
		valor: 'NÃO'
	}, {
		id: 2,
		valor: 'SIM'
	}]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PROCESSO_STATUS_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PROCESSO_STATUS_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'PROCESSO_STATUS_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        default:
            return state;
    }
}
