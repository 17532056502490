import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import fs from 'fs'

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './processoItemForm';
import FormatUtils from '../../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaStatus } from './processoItemActions';

class ProcessoItem extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		if (this.props.processoFormularioValues && this.props.processoFormularioValues.id) {
			this.props.getLista(this.props.processoFormularioValues.id);
		}
		this.props.getListaTipo();
		this.props.getListaStatus();
    }

    render() {
        return (
            <div>
                <Content>
					<Row>
						<Grid cols='12 12 12 12'>
							<label>Itens</label>
						</Grid>
					</Row>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;

		let precoTotal = 0;
		let valorTotal = 0;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								disabled={this.props.contasReceber}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {
										id_processo: this.props.processoFormularioValues && this.props.processoFormularioValues.id ? this.props.processoFormularioValues.id : null
									});
									this.props.initForm({
										id_processo: this.props.processoFormularioValues && this.props.processoFormularioValues.id ? this.props.processoFormularioValues.id : null
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Status</Th>
								<Th>Tipo</Th>
								<Th alignRight>{!acessoFinanceiro ? '' : 'Preço'}</Th>
								<Th alignRight>{!acessoFinanceiro ? '' : 'Cobrado'}</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map(item => {

								precoTotal += parseFloat(item.preco_processo);
								valorTotal += parseFloat(item.valor_cobrado);

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td alignCenter color={item.cor_status_processo}>{item.nome_status_processo}</Td>
										<Td>{item.nome_tipo_processo}</Td>
										<Td alignRight>{!acessoFinanceiro ? '' : FormatUtils.formatarValorTela(item.preco_processo, 2)}</Td>
										<Td alignRight>{!acessoFinanceiro ? '' : FormatUtils.formatarValorTela(item.valor_cobrado, 2)}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.contasReceber}
												event={() => {
													let registro = {
														...item
													}
													this.props.setModoTela('cadastro', registro);
													this.props.initForm(registro);
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={this.props.contasReceber}
												event={() => {
													let registro = {
														...item
													}
													this.props.setModoTela('exclusao', registro);
													this.props.initForm(registro);
												}} />
										</Td>
									</Tr>
								);
							})}

							{acessoFinanceiro ? (
								<Tr>
									<Td alignRight colspan='2' fontWeight='bold'>Total</Td>
									<Td alignRight fontWeight='bold'>{FormatUtils.formatarValorTela(precoTotal, 2)}</Td>
									<Td alignRight fontWeight='bold'>{FormatUtils.formatarValorTela(valorTotal, 2)}</Td>
									<Td alignRight minWidth={100}>

									</Td>
								</Tr>
							) : null}

						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.processoItem.modoTela,
	lista: state.processoItem.lista,
	processoFormularioValues: getFormValues('processoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaStatus }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoItem);
