import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import ProcessoItem from './processoItem/processoItem';
import ProcessoFinanceiro from './processoFinanceiro/processoFinanceiro';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela, initForm
} from './processoActions';

import { getLista as getListaItem, getListaHistorico } from './processoItem/processoItemActions';
import { getLista as getListaFinanceiro } from './processoFinanceiro/processoFinanceiroActions';
import DateFormat from '../common/dateFormat/DateFormat';

class ProcessoForm extends Component {

	state = {

	}

    componentWillMount() {
		if (this.props.formularioValues.id) {
			this.props.getListaItem(this.props.formularioValues.id);
			this.props.getListaFinanceiro(this.props.formularioValues.id);
			this.props.getListaHistorico(this.props.formularioValues.id);
		}
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let cliente = this.props.listaCliente.filter(item => item.id_cliente == this.props.formularioValues.id_cliente)[0];

		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;

        return (
			<>
				<ContentCard>

					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='id_cliente'
									component={Select}
									options={this.props.listaCliente}
									label='Cliente *'
									cols='12 12 12 12'
									placeholder='Selecione o cliente'
									readOnly={readOnly} />

								{cliente ? (
									<>
										<Grid cols='12 4 3 2'>
											<label>Nome:</label> {cliente.nome}
										</Grid>
										<Grid cols='12 4 3 2'>
											<label>CPF:</label> {FormatUtils.formatarCPF(cliente.cpf)}
										</Grid>
										<Grid cols='12 4 3 2'>
											<label>RG:</label> {cliente.rg}
										</Grid>
										<Grid cols='12 4 3 2'>
											<label>CR:</label> {cliente.cr}
										</Grid>
										<Grid cols='12 4 3 2' style={{ marginBottom: 12 }}>
											<label>Gov BR:</label> {cliente.usuario_gov_br} - {cliente.senha_gov_br}
										</Grid>
									</>
								) : null}
							</Row>
							<Row>
								<Field
									name='id_unidade_vinculacao'
									component={Select}
									options={this.props.listaUnidadeVinculacao}
									label='Unidade de Vinculação'
									cols='12 12 12 12'
									placeholder='Selecione a Unidade de Vinculação'
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='id_parceiro'
									component={Select}
									options={this.props.listaParceiro}
									label='Parceiro'
									cols='12 12 12 12'
									placeholder='Selecione o parceiro'
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='descricao'
									component={LabelAndInput}
									label='Descrição' placeholder='Informe a descrição'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											disabled={this.props.contasReceber}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										disabled={this.props.contasReceber}
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>

					{this.props.formularioValues.id ? (
						<ProcessoItem contasReceber={this.props.contasReceber} />
					) : null}

					{this.props.formularioValues.id ? (
						<ProcessoFinanceiro contasReceber={this.props.contasReceber} />
					) : null}
				</ContentCard>

				<Table responsive>
					<THead>
						<Tr>
							<Th> Data/Hora Modificação</Th>
							<Th alignCenter>Histórico</Th>
							<Th alignCenter>Status</Th>
							<Th>Tipo</Th>
							<Th>Descrição</Th>
							<Th alignRight>{!acessoFinanceiro ? '' : 'Preço'}</Th>
							<Th alignRight>{!acessoFinanceiro ? '' : 'Cobrado'}</Th>
							<Th alignCenter>Usuário</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.listaHistorico.map(item => {
							return (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td >{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao) }</Td>
									<Td alignCenter>{item.operacao}</Td>
									<Td alignCenter color={item.cor_status_processo}>{item.nome_status_processo}</Td>
									<Td>{item.nome_tipo_processo}</Td>
									<Td>{item.descricao}</Td>
									<Td alignRight>{!acessoFinanceiro ? '' : FormatUtils.formatarValorTela(item.preco_processo, 2)}</Td>
									<Td alignRight>{!acessoFinanceiro ? '' : FormatUtils.formatarValorTela(item.valor_cobrado, 2)}</Td>
									<Td alignCenter>{item.nome_usuario_alteracao}</Td>
								</Tr>
							);
						})}
					</TBody>
				</Table>
			</>
        )
    }

}

ProcessoForm = reduxForm({form: 'processoForm', destroyOnUnmount: false})(ProcessoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('processoForm')(state),
	registro: state.processo.registro,
	listaCliente: state.processo.listaCliente,
	listaParceiro: state.processo.listaParceiro,
	listaHistorico: state.processoItem.listaHistorico,
	listaUnidadeVinculacao: state.processo.listaUnidadeVinculacao
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaItem, getListaFinanceiro, getListaHistorico
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoForm);
