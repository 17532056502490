import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import FormatUtils from '../common/formatUtils/FormatUtils';
import {logoBase64} from "../utils/logoBase64";


export function gerarPDF(listaContasReceber) {
  const nomeArquivo = "ContasReceber.pdf";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

    
    const logo = {
      image: logoBase64,
      fit: [100, 100],
      alignment: "left",
    };
    
    let soma = listaContasReceber.reduce((acc, item) => {
      return acc += Number(item.receber)
    }, 0);
  

  const tabela = {
    table: {
      widths: ["50%", "50%"],
      // heights: [20, 20, 40, 10, 80, 80, 40, 10],
      headerRows: 0,
    
      body: [
        [
          {
            columns: [
              logo,
              {
                text: " Mallet Despachante " ,
                style: "titulo2",
                alignment: "left",
                margin: [0, 5, 0, 0],
              },
              {
                text: "Data: " + new Date().toLocaleDateString('pt-BR', {dateStyle:'long'}),
                style: "titulo2",
                alignment: "left",
                margin: [0, 5, 0, 0],
              },
            ],
            colSpan: 2,
          },
          {},
        ],
        [
          {
            text: "Cliente",
            style: "titulo",
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: "Receber",
            style: "titulo",
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
          ...listaContasReceber.map((item) => [
          {
            text:[ item.nome_cliente],
            alignment: "left",
           
          },
          {
            
            text: "R$ "+FormatUtils.formatarValorTela(item.receber, 2),
            alignment: "right",
          },

        ]),
        [ {
          columns: [
           
            {
              text: " Total " ,
              style: "titulo2",
              alignment: "left",
              margin: [0, 5, 0, 0],
            },
            {
              text: "R$ " + FormatUtils.formatarValorTela(soma, 2),
              style: "titulo2",
              alignment: "right",
              margin: [0, 5, 0, 0],
            },
          ],
          colSpan: 2,
        },
        {},]
        
      ],
    },
  };



  const documento = {
    pageSize: "A4",
    pageMargins: [15, 45, 15, 0],
    info: {
      title: nomeArquivo,
    },
    content: [tabela],
    styles: { titulo: { fontSize: 11, bold: true }, titulo2: { fontSize: 13, bold: true,}},
    defaultStyle: {
      fontSize: 10,
   
      
    },
  };

  pdfMake.createPdf(documento).open();
}