import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './processoForm';
import Select from '../common/form/select';
import imagemSoLogo from '../assets/images/sologo.png';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Documento from './processoDocumento';
import DocumentoForm from './processoDocumentoForm';
import Switch from 'react-switch';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaCliente, getListaUnidadeVinculacao, getListaParceiro, initFormDocumento, salvarDocumento, excluirDocumento } from './processoActions';
import { getListaTipo, getListaStatus } from './processoItem/processoItemActions';
import processoItem from './processoItem/processoItem';

class ProcessoResumido extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		mostraStatus: true,	}

	componentWillMount() {
		setTimeout(() => {
			this.props.getLista();
		}, 1000);
		this.props.getListaCliente();
		this.props.getListaTipo();
		this.props.getListaStatus();
		this.props.getListaParceiro();
		this.props.getListaUnidadeVinculacao()
	}

	render() {
		return (
			<div>
				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: '50%', marginRight: '50%' }}>
									<img src={imagemSoLogo} style={{ height: 40, width: 40, marginBottom: -76, marginLeft: 8 }} />
								</div>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'cadastroDocumento' ? (
						<DocumentoForm onSubmit={this.props.salvarDocumento} />
					) : null}
					{this.props.modoTela == 'exclusaoDocumento' ? (
						<DocumentoForm excluir onSubmit={this.props.excluirDocumento} />
					) : null}
					{this.props.modoTela == 'listaDocumento' ? (
						<Documento />
					) : null}
				</Content>
			</div>
		)
	}

	lista() {

		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;
		let acessoUsuario = this.props.usuarioLogado && this.props.usuarioLogado.acesso_usuario;
		let acessoResumido = this.props.usuarioLogado && this.props.usuarioLogado.acesso_resumido ? true : false;

		let lista = this.props.lista;
		let listaStatusIcon = this.props.listaStatusIcon;



		return (

			<><Row>
				{this.props.listaStatus.map(item => {
					let statusProcesso = lista.filter(processo => processo.itens.filter(processoItem => processoItem.id_status == item.id).length > 0).length;
					let statusIcon = listaStatusIcon.filter(statusIcon => statusIcon.id == item.id)[0];
					let total = lista.length;

					return (


						<Grid cols='12 4 3 3'>
							<div class='info-box'>
								<span class='info-box-icon' style={{ background: item.cor, color: '#fff' }}><i class={statusIcon ? statusIcon.icon : null} aria-hidden='true'></i></span>
								<div class='info-box-content'>
									<span class='info-box-text' style={{ fontSize: 11 }} > {item.nome.substring(0, 22)} </span>
									<span class='info-box-number'>{statusProcesso}</span>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<span class='info-box-number'>{total ? parseInt((statusProcesso * 100) / total) : 0}<small>%</small></span>
										<Switch uncheckedIcon={null} checkedIcon={null}
											onChange={() => {

												let status = this.props.filtro.status;
												if (status.filter(itemStatus => itemStatus == item.id).length > 0) {
													status = status.filter(itemStatus => itemStatus != item.id);
												} else {
													status.push(item.id);
												}
												this.setState({ ...this.state, mostraStatus: !this.state.mostraStatus });

												this.props.setFiltro({
													...this.props.filtro,
													status: status
												});
												this.props.getLista();

											}} checked={this.props.filtro.status.filter(itemStatus => itemStatus == item.id).length > 0} />
									</div>
								</div>
							</div>
						</Grid>

					);
				})}
			</Row><ContentCard>
					<ContentCardHeader>
						<Row>
							{!acessoResumido ? (
								<Grid cols='6 6 4 2'>
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.setModoTela('cadastro', {});
											this.props.initForm({});
										}} />
								</Grid>
							) : null}

							{acessoFinanceiro ? (
								<Grid cols='6 6 4 2'>
									<Button
										text='Imprimir'
										type={'primary'}
										icon={'fa fa-plus'}
										event={() => {
											this.imprimir();
										}} />
								</Grid>
							) : null}
						</Row>
					</ContentCardHeader>

					<ContentCardBody>

						<Row>
							<Select
								name='id_cliente'
								options={this.props.listaCliente}
								label='Cliente'
								cols='12 6 3 2'
								placeholder='Selecione o Cliente'
								value={this.props.filtro.id_cliente}
								onChange={value => {
									this.props.setFiltro({
										...this.props.filtro,
										id_cliente: value
									});
									this.props.getLista();
								}} />

							<Select
								name='id_status'
								options={this.props.listaStatus}
								label='Status'
								cols='12 6 3 2'
								placeholder='Selecione o Status'
								value={this.props.filtro.id_cliente}
								onChange={value => {
									this.props.setFiltro({
										...this.props.filtro,
										id_status: value
									});
									this.props.getLista();
								}} />

							<Select
								name='id_tipo'
								options={this.props.listaTipo}
								label='Processo'
								cols='12 6 3 2'
								placeholder='Selecione o Processo'
								value={this.props.filtro.id_tipo}
								onChange={value => {
									this.props.setFiltro({
										...this.props.filtro,
										id_tipo: value
									});
									this.props.getLista();
								}} />

							{!acessoResumido ? (
								<Select
									name='id_parceiro'
									options={this.props.listaParceiro}
									label='Parceiro'
									cols='12 6 3 2'
									placeholder='Selecione o Parceiro'
									value={this.props.filtro.id_parceiro}
									onChange={value => {
										this.props.setFiltro({
											...this.props.filtro,
											id_parceiro: value
										});
										this.props.getLista();
									}} />
							) : null}
								<Select
								name='id_unidade_vinculacao'
								options={this.props.listaUnidadeVinculacao}
								label='Unidade Vinculação'
								cols='12 6 3 2'
								placeholder='Selecione a Unidade de Vinculação'
								value={this.props.filtro.id_unidade_vinculacao}
								onChange={value => {
									this.props.setFiltro({
										...this.props.filtro,
										id_unidade_vinculacao: value
									});
									this.props.getLista();
								}} />
						</Row>

						<Table responsive>
							<THead>
								<Tr>
									<Th colspan={1}>Cliente</Th>
									<Th colspan={1} alignCenter>Status</Th>
									<Th colspan={1} alignCenter>Tipo</Th>
									<Th colspan={1}></Th>
								</Tr>
							</THead>
							<TBody>

								{lista.map(item => {

									if (item.itens.length == 0) {
										return (
											<Tr key={item.id}
												style={{
													backgroundColor: this.state.linhaSelecionada == item.id ? '#ececec' : '#fff'
												}}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}>
												<Td>{item.nome_cliente}</Td>
												<Td></Td>
												<Td></Td>
											</Tr>
										);
									} else {

										let totalCobrado = 0;

										item.itens.forEach(item => {
											totalCobrado += parseFloat(item.valor_cobrado);
										});

										return (
											<>
												{item.itens.map((processo, i) => (
													<Tr key={item.id}
														onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
														onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
														style={{
															backgroundColor: this.state.linhaSelecionada == item.id ? '#ececec' : '#fff'
														}}>
														{i == 0 ? (
															<Td rowspan={item.itens.length}>{item.nome_cliente}</Td>
														) : null}
														<Td alignCenter color={processo.cor_status_processo}>{processo.nome_status_processo}</Td>
														<Td alignCenter>{processo.nome_tipo_processo}</Td>
													</Tr>
												))}
											</>
										);
									}
								})}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard></>
		)
	}

	imprimir() {

		const doc = new jsPDF({
			orientation: 'landscape',
		});

		let colunas = ['Cliente', 'Status', 'Tipo', 'Abertura', 'Última Alteração', 'Parceiro'];

		let dados = [];
		this.props.lista.forEach(item => {
			item.itens.forEach(processo => {
				dados.push([
					item.nome_cliente,
					processo.nome_status_processo,
					processo.nome_tipo_processo,
					item.nome_parceiro,
					item.nome_unidade_vinculacao
				]);
			});
		});

		doc.autoTable(colunas, dados, { startY: 20 });

		doc.save(`relatorio.pdf`);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.processo.modoTela,
	aguardando: state.processo.aguardando,
	filtro: state.processo.filtro,
	lista: state.processo.lista,
	listaCliente: state.processo.listaCliente,
	listaUnidadeVinculacao: state.processo.listaUnidadeVinculacao,
	listaParceiro: state.processo.listaParceiro,
	listaUnidadeConsumidora: state.processo.listaUnidadeConsumidora,
	listaMeses: state.processo.listaMeses,
	listaTipo: state.processoItem.listaTipo,
	listaStatus: state.processoItem.listaStatus,
	listaStatusIcon: state.processo.listaStatusIcon,

});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaCliente, getListaTipo, getListaStatus, getListaUnidadeVinculacao, getListaParceiro, initFormDocumento, salvarDocumento, excluirDocumento }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoResumido);
