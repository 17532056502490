import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ClienteReducer from '../cliente/clienteReducer';
import ProcessoTipoReducer from '../processoTipo/processoTipoReducer';
import ProcessoStatusReducer from '../processoStatus/processoStatusReducer';
import ClubeReducer from '../clube/clubeReducer';
import UnidadeVinculacaoReducer from '../unidadeVinculacao/unidadeVinculacaoReducer';
import ParceiroReducer from '../parceiro/parceiroReducer';
import ProcessoReducer from '../processo/processoReducer';
import ProcessoItemReducer from '../processo/processoItem/processoItemReducer';
import ProcessoFinanceiroReducer from '../processo/processoFinanceiro/processoFinanceiroReducer';
import RelatorioFinanceiroReducer from '../relatorioFinanceiro/relatorioFinanceiroReducer';
import AgendaDAO from '../agenda/agendaReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	cliente: ClienteReducer,
	processoTipo: ProcessoTipoReducer,
	processoStatus: ProcessoStatusReducer,
	clube: ClubeReducer,
	unidadeVinculacao: UnidadeVinculacaoReducer,
	parceiro: ParceiroReducer,
	processo: ProcessoReducer,
	processoItem: ProcessoItemReducer,
	processoFinanceiro: ProcessoFinanceiroReducer,
	relatorioFinanceiro: RelatorioFinanceiroReducer,
	agenda: AgendaDAO,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
