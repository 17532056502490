import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import fs from 'fs'

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './processoFinanceiroForm';
import FormatUtils from '../../common/formatUtils/FormatUtils';
import DateFormat from '../../common/dateFormat/DateFormat';

import { setModoTela, initForm, salvar, excluir, getLista } from './processoFinanceiroActions';

class ProcessoFinanceiro extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		if (this.props.processoFormularioValues && this.props.processoFormularioValues.id) {
			this.props.getLista(this.props.processoFormularioValues.id);
		}
    }

    render() {
        return (
            <div>
                <Content>
					<Row>
						<Grid cols='12 12 12 12'>
							<label>Financeiro</label>
						</Grid>
					</Row>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;

		let despesaTotal = 0;
		let receitaTotal = 0;
		let totalCobrado = 0;
		this.props.listaItem.forEach(item => {
			totalCobrado += parseFloat(item.valor_cobrado);
		});

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						{acessoFinanceiro ? (
							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar Receita'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', {
											id_processo: this.props.processoFormularioValues && this.props.processoFormularioValues.id ? this.props.processoFormularioValues.id : null,
											entrada: true
										});
										this.props.initForm({
											id_processo: this.props.processoFormularioValues && this.props.processoFormularioValues.id ? this.props.processoFormularioValues.id : null,
											entrada: true
										});
									}} />
							</Grid>
						) : null}
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar Despesa'
								type={'danger'}
								icon={'fa fa-plus'}
								disabled={this.props.contasReceber}
								event={() => {
									this.props.setModoTela('cadastro', {
										id_processo: this.props.processoFormularioValues && this.props.processoFormularioValues.id ? this.props.processoFormularioValues.id : null,
										entrada: false
									});
									this.props.initForm({
										id_processo: this.props.processoFormularioValues && this.props.processoFormularioValues.id ? this.props.processoFormularioValues.id : null,
										entrada: false
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Tipo</Th>
								<Th>Descrição</Th>
								<Th alignRight>{!acessoFinanceiro ? '' : 'Valor'}</Th>
								<Th alignCenter>Data Pagamento</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map(item => {

								if (item.entrada) {
									receitaTotal += parseFloat(item.valor);
								} else {
									despesaTotal += parseFloat(item.valor);
								}

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td alignCenter>{item.entrada ? 'Receita' : 'Despesa'}</Td>
										<Td>{item.descricao}</Td>
										<Td alignRight>{!acessoFinanceiro ? '' : (item.entrada ? '(+)' : '(-)')} {!acessoFinanceiro ? '' : FormatUtils.formatarValorTela(item.valor, 2)}</Td>
										<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_pagamento)}</Td>
										<Td alignRight minWidth={100}>
											{acessoFinanceiro ? (
												<>
													<ButtonTable
														type={'warning'}
														icon={'fas fa-pencil-alt'}
														visible={this.state.linhaSelecionada == item.id}
														disabled={this.props.contasReceber && !item.entrada}
														event={() => {
															let registro = {
																...item
															}
															this.props.setModoTela('cadastro', registro);
															this.props.initForm(registro);
														}} />

													<ButtonTable
														type={'danger'}
														icon={'fas fa-trash-alt'}
														visible={this.state.linhaSelecionada == item.id}
														disabled={this.props.contasReceber && !item.entrada}
														event={() => {
															let registro = {
																...item
															}
															this.props.setModoTela('exclusao', registro);
															this.props.initForm(registro);
														}} />
												</>
											) : null}
										</Td>
									</Tr>
								);
							})}

							{acessoFinanceiro ? (
								<>
									<Tr>
										<Td alignRight colspan='2' fontWeight='bold'>Total Receita</Td>
										<Td alignRight fontWeight='bold'>{FormatUtils.formatarValorTela(receitaTotal, 2)}</Td>
										<Td alignRight minWidth={100}></Td>
									</Tr>
									<Tr>
										<Td alignRight colspan='2' fontWeight='bold'>Total Despesa</Td>
										<Td alignRight fontWeight='bold'>{FormatUtils.formatarValorTela(despesaTotal, 2)}</Td>
										<Td alignRight minWidth={100}></Td>
									</Tr>
									<Tr>
										<Td alignRight colspan='2' fontWeight='bold'>Resultado</Td>
										<Td alignRight fontWeight='bold'>{FormatUtils.formatarValorTela(receitaTotal - despesaTotal, 2)}</Td>
										<Td alignRight minWidth={100}></Td>
									</Tr>
									<Tr>
										<Td alignRight colspan='2' fontWeight='bold'>A Receber</Td>
										<Td alignRight fontWeight='bold'>{FormatUtils.formatarValorTela(totalCobrado - receitaTotal > 0 ? totalCobrado - receitaTotal : 0, 2)}</Td>
										<Td alignRight minWidth={100}></Td>
									</Tr>
								</>
							) : null}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.processoFinanceiro.modoTela,
	lista: state.processoFinanceiro.lista,
	listaItem: state.processoItem.lista,
	processoFormularioValues: getFormValues('processoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoFinanceiro);
