import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './processoForm';
import Select from '../common/form/select';
import imagemSoLogo from '../assets/images/sologo.png';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { gerarPDF } from '../utils/gerarPdf';

import { setModoTela, initForm, setFiltro, salvar, excluir, getListaContasReceber, getListaCliente, getListaParceiro } from './processoActions';
import { getListaTipo, getListaStatus } from './processoItem/processoItemActions';

class ProcessoContasReceber extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getListaContasReceber();
		this.props.getListaCliente();
		this.props.getListaTipo();
		this.props.getListaStatus();
		this.props.getListaParceiro();
	}

	render() {
		return (
			<div>
				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: '50%', marginRight: '50%' }}>
									<img src={imagemSoLogo} style={{ height: 40, width: 40, marginBottom: -76, marginLeft: 8 }} />
								</div>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'contasReceber' ? (
						<Form onSubmit={this.props.salvar} contasReceber />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {	
		let soma = this.props.listaContasReceber.reduce((acc, item) => {
			return acc += Number(item.receber)
		  }, 0);
		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;

		return (
			<ContentCard>
				<ContentCardBody>
					<Row>
						<Select
							name='id_cliente'
							options={this.props.listaCliente}
							label='Cliente'
							cols='12 6 3 3'
							placeholder='Selecione o Cliente'
							value={this.props.filtro.id_cliente}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cliente: value
								});
								this.props.getListaContasReceber();
							}} />

						<Button
							type={'danger'}
							icon={''}
							text='Gerar PDF'
							style={{width: 100, height:50, marginTop: 24}}
							event={() => {
								gerarPDF(this.props.listaContasReceber)
							}} />
					
					</Row>

					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Cliente</Th>
								<Th colspan={1} alignRight>Receber</Th>
								
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaContasReceber.map(item => {
								return (
									<Tr key={item.id}
										style={{
											backgroundColor: this.state.linhaSelecionada == item.id ? '#ececec' : '#fff'
										}}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_cliente}</Td>
										<Td alignRight>{FormatUtils.formatarValorTela(item.receber, 2)}</Td>
										
										<Td alignRight minWidth={150}>
											<ButtonTable
												type={'success'}
												icon={'fas fa-dollar-sign'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('contasReceber', item);
													this.props.initForm(item);
												}} />
										</Td>
									</Tr>
								);
							})}
							<Td> TOTAL</Td>
							<Td alignRight> R$ {FormatUtils.formatarValorTela(soma, 2)}</Td>
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	imprimir() {

		const doc = new jsPDF({
			orientation: 'landscape',
		});

		let colunas = ['Cliente', 'Status', 'Tipo', 'Abertura', 'Última Alteração', 'Parceiro'];

		let dados = [];
		this.props.lista.forEach(item => {
			item.itens.forEach(processo => {
				dados.push([
					item.nome_cliente,
					processo.nome_status_processo,
					processo.nome_tipo_processo,
					DateFormat.formatarDataSqlParaTela(processo.datahora_inclusao),
					DateFormat.formatarDataSqlParaTela(processo.datahora_alteracao),
					item.nome_parceiro
				]);
			});
		});

		doc.autoTable(colunas, dados, { startY: 20 });

		doc.save(`relatorio.pdf`);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.processo.modoTela,
	aguardando: state.processo.aguardando,
	filtro: state.processo.filtro,
	listaContasReceber: state.processo.listaContasReceber,
	listaCliente: state.processo.listaCliente,
	listaParceiro: state.processo.listaParceiro,
	listaUnidadeConsumidora: state.processo.listaUnidadeConsumidora,
	listaMeses: state.processo.listaMeses,
	listaTipo: state.processoItem.listaTipo,
	listaStatus: state.processoItem.listaStatus
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, setFiltro, salvar, excluir, getListaContasReceber, getListaCliente, getListaTipo, getListaStatus, getListaParceiro }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoContasReceber);
