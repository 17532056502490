import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PROCESSO_FINANCEIRO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				valor: FormatUtils.formatarValorTela(registro.valor, 2),
				data_pagamento: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_pagamento))
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('processoFinanceiroForm', {
			...registro,
			valor: FormatUtils.formatarValorTela(registro.valor, 2),
			data_pagamento: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_pagamento))
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'PROCESSO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(idProcesso) {
    const request = axios.get(`${consts.API_URL}/processoFinanceiro?id_processo=${idProcesso}`);
    return {
        type: 'PROCESSO_FINANCEIRO_LISTADO',
        payload: request
    };
}

export function getListaProcesso() {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/processo?
			id_cliente=${getState().processo.filtro.id_cliente || null}
			&ano=${getState().processo.filtro.ano}
			&mes=${getState().processo.filtro.mes}`)
		.then(resp => {
			dispatch({
				type: 'PROCESSO_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/processoFinanceiro`, {
				...registro,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_processo));
				dispatch(getListaProcesso());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/processoFinanceiro`, {
				...registro,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_processo));
				dispatch(getListaProcesso());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/processoFinanceiro?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_processo));
			dispatch(getListaProcesso());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
