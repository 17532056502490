import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Cliente from '../cliente/cliente';
import ProcessoTipo from '../processoTipo/processoTipo';
import ProcessoStatus from '../processoStatus/processoStatus';
import Clube from '../clube/clube';
import UnidadeVinculacao from '../unidadeVinculacao/unidadeVinculacao';
import Parceiro from '../parceiro/parceiro';
import Processo from '../processo/processo';
import ProcessoResumido from '../processo/processoResumido';
import ProcessoContasReceber from '../processo/processoContasReceber';
import RelatorioFinanceiro from '../relatorioFinanceiro/relatorioFinanceiro';
import Agenda from '../agenda/agenda';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/processoTipo' component={ProcessoTipo} />
			<Route path='/processoStatus' component={ProcessoStatus} />
			<Route path='/clube' component={Clube} />
			<Route path='/unidadeVinculacao' component={UnidadeVinculacao} />
			<Route path='/parceiro' component={Parceiro} />
			<Route path='/processo' component={Processo} />
			<Route path='/processoResumido' component={ProcessoResumido} />
			<Route path='/processoContasReceber' component={ProcessoContasReceber} />
			<Route path='/relatorioFinanceiro' component={RelatorioFinanceiro} />
			<Route path='/agenda' component={Agenda} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
