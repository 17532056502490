import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import imagemSoLogo from '../assets/images/sologo.png';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputDate from '../common/form/labelAndInputDate';


import {
	getLista, setFiltro, getListaCliente, getListaParceiro, getListaTipo
} from './relatorioFinanceiroActions';

class RelatorioFinanceiro extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getLista();
		this.props.getListaCliente();
		this.props.getListaTipo();
		this.props.getListaParceiro();
	}





	render() {

	
		return (
			<div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: '50%', marginRight: '50%' }}>
									<img src={imagemSoLogo} style={{ height: 40, width: 40, marginBottom: -76, marginLeft: 8 }} />
								</div>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {
	
		let totalEntrada = 0;
		let totalSaida = 0;


		return (
			<ContentCard>
				<Row>
					<Select
						name='id_cliente'
						options={this.props.listaCliente}
						label='Cliente'
						cols='12 6 3 3'
						placeholder='Selecione o Cliente'
						value={this.props.filtro.id_cliente}
						onChange={value => {
							this.props.setFiltro({
								...this.props.filtro,
								id_cliente: value
							});
							this.props.getLista();
						}} />

					<Select
						name='id_processo'
						options={this.props.listaTipo}
						label='Processo'
						cols='12 6 3 3'
						placeholder='Selecione o Processo'
						value={this.props.filtro.id_processo}
						onChange={value => {
							this.props.setFiltro({
								...this.props.filtro,
								id_processo: value
							});
							this.props.getLista();
						}} />


					<Select
						name='id_parceiro'
						options={this.props.listaParceiro}
						label='Parceiro'
						cols='12 6 3 3'
						placeholder='Selecione o Parceiro'
						value={this.props.filtro.id_parceiro}
						onChange={value => {
							this.props.setFiltro({
								...this.props.filtro,
								id_parceiro: value
							});
							this.props.getLista();
						}} />

					<LabelAndInputDate
						name='data_inicial'
						label='Data  Inicio' placeholder='Informe a Data Inicio'
						cols='12 6 4 4'

						value={this.props.filtro.data_inicial}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								data_inicial: data.target.value
							});
							this.props.getLista();
						}} />
					<LabelAndInputDate
						name='data_final'
						label='Data Final' placeholder='Informe a Data Final'
						cols='12 6 4 4'

						value={this.props.filtro.data_final}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								data_final: data.target.value
							});
							this.props.getLista();
						}} />

				</Row>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome Cliente</Th>
								<Th alignCenter>Data/Hora</Th>
								<Th alignCenter>Tipo</Th>
								<Th>Descrição</Th>
								<Th alignRight>Valor</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => {
								switch (item.tipo) {
									case 'Entrada':
										totalEntrada += parseFloat(item.valor);
										break;
									case 'Saída':
										totalSaida += parseFloat(item.valor);
									default:

										break;
								}

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_cliente}</Td>
										<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
										<Td alignCenter>{item.tipo}</Td>
										<Td>{item.descricao}</Td>
										<Td alignRight >{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									</Tr>
								)
							})}

								<Tr>
									<Td></Td>
									<Td alignCenter></Td>
									<Td alignRight>Entrada </Td>
									<Td></Td>
									<Td alignRight >{FormatUtils.formatarValorTela(totalEntrada, 2)}</Td>
								</Tr>
								<Tr>
									<Td></Td>
									<Td alignRight></Td>
									<Td alignRight>Saída </Td>
									<Td></Td>
									<Td alignRight >{FormatUtils.formatarValorTela(totalSaida, 2)}</Td>
								</Tr>
								<Tr>
									<Td></Td>
									<Td alignRight></Td>
									<Td alignRight>Resultado do Período </Td>
									<Td></Td>
									<Td alignRight >{FormatUtils.formatarValorTela(totalEntrada + totalSaida, 2)}</Td>
								</Tr>
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.relatorioFinanceiro.modoTela,
	lista: state.relatorioFinanceiro.lista,
	aguardando: state.relatorioFinanceiro.aguardando,
	filtro: state.relatorioFinanceiro.filtro,
	listaCliente: state.relatorioFinanceiro.listaCliente,
	listaParceiro: state.relatorioFinanceiro.listaParceiro,
	listaTipo: state.relatorioFinanceiro.listaTipo,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista, setFiltro, getListaCliente, getListaParceiro, getListaTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioFinanceiro);
