const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaEstado: [],
	listaCidade: [],
	listaClube: [],
	listaUnidadeVinculacao: [],
	listaDocumento: [],
	filtro: {
		id_clube: null,
		id_unidade_vinculacao: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CLIENTE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CLIENTE_FILTRO':
			return { ...state, filtro: action.payload };

        case 'CLIENTE_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CLIENTE_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'CLIENTE_ESTADO_SELECT_LISTADO':
            return {
				...state,
				listaEstado: action.payload.data
			};

        case 'CLIENTE_CIDADE_SELECT_LISTADO':
            return {
				...state,
				listaCidade: action.payload.data
			};

        case 'CLIENTE_CLUBE_SELECT_LISTADO':
            return {
				...state,
				listaClube: action.payload.data
			};

        case 'CLIENTE_UNIDADE_VINCULACAO_SELECT_LISTADO':
            return {
				...state,
				listaUnidadeVinculacao: action.payload.data
			};

		case 'CLIENTE_DOCUMENTO_LISTADO':
            return {
				...state,
				listaDocumento: action.payload.data
			};

        default:
            return state;
    }
}
