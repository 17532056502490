import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import FormatUtils from '../../common/formatUtils/FormatUtils';

import {
	setModoTela, initForm
} from './processoItemActions';

class ProcessoItemForm extends Component {

	state = {
		modoCadastroProduto: false,
		produto: null,
		modoCadastroUnidadeMedida: false,
		unidadeMedida: null
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Select
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo de Processo *'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly}
								value={this.props.formularioValues.id_tipo}
								onChange={value => {

									let tipo = this.props.listaTipo.filter(item => item.id == value)[0];

									this.props.initForm({
										...this.props.formularioValues,
										id_tipo: tipo ? tipo.id : null,
										preco_processo: tipo ? FormatUtils.formatarValorTela(tipo.preco, 2) : 0
									})
								}} />
						</Row>
						<Row>
							<Field
								name='id_status'
								component={Select}
								options={this.props.listaStatus}
								label='Status *'
								cols='12 12 12 12'
								placeholder='Selecione o status'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
						{acessoFinanceiro || !this.props.formularioValues.id ? (
							<Row>
								<Field
									name='preco_processo'
									component={LabelAndInputNumber}
									label='Preço do Processo' placeholder='Informe o preço'
									cols='12 12 4 4'
									casas={2}
									readOnly='readOnly' />

								<Field
									name='valor_cobrado'
									component={LabelAndInputNumber}
									label='Valor Cobrado' placeholder='Informe o valor'
									cols='12 12 4 4'
									casas={2}
									readOnly={readOnly} />
							</Row>
						) : null}
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }
}

ProcessoItemForm = reduxForm({form: 'processoItemForm', destroyOnUnmount: false})(ProcessoItemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('processoItemForm')(state),
	registro: state.processoItem.registro,
	listaTipo: state.processoItem.listaTipo,
	listaStatus: state.processoItem.listaStatus
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoItemForm);
