const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaHistorico: [],
	listaTipo: [],
	listaStatus: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PROCESSO_ITEM_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PROCESSO_ITEM_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'PROCESSO_ITEM_HISTORICO_LISTADO':
            return {
				...state,
				listaHistorico: action.payload.data
			};

        case 'PROCESSO_ITEM_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};

        case 'PROCESSO_ITEM_STATUS_SELECT_LISTADO':
			return {
				...state,
				listaStatus: action.payload.data
			};

        default:
            return state;
    }
}
