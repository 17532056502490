import React, { Component } from 'react';
import MenuItem from './menuItem';
import MenuTree from './menuTree';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout } from '../../auth/authActions';

class Menu extends Component {

	state = {
		carregado: false
	};

	componentWillMount() {
		setTimeout(() => {
			this.setState({ ...this.state, carregado: true });
		}, 10000)
    }

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		let acessoUsuario = this.props.usuarioLogado && this.props.usuarioLogado.acesso_usuario ? true : false;
		let acessoCadastro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_cadastro ? true : false;
		let acessoFinanceiro = this.props.usuarioLogado && this.props.usuarioLogado.acesso_financeiro;
		let acessoResumido = this.props.usuarioLogado && this.props.usuarioLogado.acesso_resumido ? true : false;

		if (this.props.usuarioLogado) {
			if (!acessoUsuario && selecionado == '/usuario') {
				window.location = '#';
			}
			if (acessoResumido && selecionado != '/processoResumido') {
				window.location = '#/processoResumido';
			}
			if (!acessoCadastro && (selecionado == '/clube' || selecionado == '/unidadeVinculacao'
				|| selecionado == '/parceiro' || selecionado == '/cliente' || selecionado == '/processoTipo'
				|| selecionado == '/processoStatus' || selecionado == '/agenda')) {
				window.location = '#';
			}
			if (!acessoFinanceiro && (selecionado == '/processoContasReceber' || selecionado == '/processoStatus')) {
				window.location = '#';
			}
		} else if (this.state.carregado) {
			this.props.logout();
		}

		if (!this.props.usuarioLogado) {
			return null;
		}

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					{!acessoResumido ? (
						<MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />
					) : (
						<MenuItem path='processoResumido' label='Dashboard' icon='fas fa-stream' active={selecionado == '/processoResumido'} />
					)}
					{acessoUsuario ? (
						<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
					) : null}
					{acessoCadastro ? (
						<MenuItem path='clube' label='Clube' icon='fas fa-bullseye' active={selecionado == '/clube'} />
					) : null}
					{acessoCadastro ? (
						<MenuItem path='unidadeVinculacao' label='Unidade de Vinculação' icon='fas fa-archway' active={selecionado == '/unidadeVinculacao'} />
					) : null}
					{acessoCadastro ? (
						<MenuItem path='parceiro' label='Parceiro' icon='fas fa-handshake' active={selecionado == '/parceiro'} />
					) : null}
					{acessoCadastro ? (
						<MenuItem path='cliente' label='Cliente' icon='fas fa-user-circle' active={selecionado == '/cliente'} />
					) : null}
					{acessoCadastro ? (
						<MenuItem path='processoTipo' label='Tipo de Processo' icon='fas fa-bars' active={selecionado == '/processoTipo'} />
					) : null}
					{acessoCadastro ? (
						<MenuItem path='processoStatus' label='Status do Processo' icon='fas fa-clipboard-list' active={selecionado == '/processoStatus'} />
					) : null}
					{!acessoResumido ? (
						<MenuItem path='processo' label='Processo' icon='fas fa-stream' active={selecionado == '/processo'} />
					) : null}
					{acessoFinanceiro ? (
						<MenuItem path='processoContasReceber' label='Contas a Receber' icon='fas fa-file-invoice-dollar' active={selecionado == '/processoContasReceber'} />
					) : null}
					{/*acessoFinanceiro ? (
						<MenuItem path='relatorioFinanceiro' label='Relatório Financeiro' icon='fas fa-hand-holding-usd' active={selecionado == '/relatorioFinanceiro'} />
					) : null*/}
					{acessoCadastro ? (
						<MenuItem path='agenda' label='Agenda' icon='far fa-address-book' active={selecionado == '/agenda'} />
					) : null}
		    	</ul>
			</nav>
		);

	}

}

const mapStateToProps = state => ({
	usuarioLogado: state.auth.usuarioLogado
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
