import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';
import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './agendaActions';

class AgendaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome_cliente'
								component={LabelAndInput}
								label='Nome Cliente *' placeholder='Informe o nome cliente'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						
							<Row>
								{console.log(this.props.formularioValues.data_inicial)}
                            <LabelAndInputDate
								name='data_inicial'
								label='Data de Início do Processo' placeholder='Informe a data início'
								cols='12 6 4 3'
								readOnly={readOnly}
								value={this.props.formularioValues.data_inicial}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										data_inicial: data.target.value
									});
								}} />
							</Row>
					

						<Row>
							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição do Processo' placeholder='Informe a descricao'
								cols='6 6 6 6'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='contato'
								component={LabelAndInputMask}
								mask='(99) 99999-9999'
								label='Contato' placeholder='Informe o contato'
								cols='6 6 6 6'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_usuario_agenda'
								component={Select}
								label='Usuário' placeholder='Informe o contato'
								cols='6 6 6 6'
								readOnly={readOnly} 
								options={this.props.listaUsuario}/>
						</Row>
                        <Row>
							<Field
								name='finalizado'
								component={Select}
								label='Finalizado' placeholder='Informe a finalizado'
								cols='6 6 6 6'
								readOnly={readOnly}
                                 options={this.props.listaFinalizado} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

AgendaForm = reduxForm({form: 'agendaForm', destroyOnUnmount: false})(AgendaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('agendaForm')(state),
	registro: state.agenda.registro,
    listaFinalizado: state.agenda.listaFinalizado,
	listaUsuario: state.agenda.listaUsuario,


});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AgendaForm);
