import DateFormat from "../common/dateFormat/DateFormat";

const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: 'lista',
	lista: [],
	listaContasReceber: [],
	listaCliente: [],
	listaUnidadeVinculacao: [],
	listaParceiro: [],
	listaDocumento: [],
	filtro: {
		ano: new Date().getFullYear(),
		mes: (() => {
			let data = new Date();
			data.setMonth(data.getMonth() - 1);

			return data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
		})(),
		id_cliente: null,
		id_status: null,
		id_tipo: null,
		id_parceiro: null,
		id_unidade_vinculacao: null,
		status: [12, 1, 7, 9, 10, 5, 15 ],
	},
	listaMeses: [
		{ id: '01', valor: 'Janeiro' }, { id: '02', valor: 'Fevereiro' }, { id: '03', valor: 'Março' }, { id: '04', valor: 'Abril' },
		{ id: '05', valor: 'Maio' }, { id: '06', valor: 'Junho' }, { id: '07', valor: 'Julho' }, { id: '08', valor: 'Agosto' },
		{ id: '09', valor: 'Setembro' }, { id: '10', valor: 'Outubro' }, { id: '11', valor: 'Novembro' }, { id: '12', valor: 'Dezembro' }
	],
	listaStatusIcon: [
		{ id: '6', icon: 'far fa-clock' }, { id: '12', icon: 'far fa-file-alt' }, { id: '4', icon: 'fas fa-calendar-check' }, { id: '11', icon: 'fas fa-ban' },
		{ id: '1', icon: 'fas fa-angle-double-right' }, { id: '7', icon: 'fas fa-hourglass-half' }, { id: '9', icon: 'fas fa-file-import' }, { id: '10', icon: 'fas fa-file-import' },
		{ id: '5', icon: 'fas fa-file-import' }, { id: '13', icon: 'fas fa-people-carry' }, { id: '15', icon: 'fas fa-exchange-alt' }, { id: '16', icon: 'fas fa-calendar-check' }
	]
};
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'PROCESSO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'PROCESSO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'PROCESSO_FILTRO':
			return { ...state, filtro: action.payload };

		case 'PROCESSO_LISTADO':
			return {
				...state,
				lista: action.payload.data.map(item => ({
					...item,
					itens: item.itens.filter(processoItem => processoItem)
				}))
			};

		case 'PROCESSO_CONTAS_RECEBER_LISTADO':
			return {
				...state,
				listaContasReceber: action.payload.data
			};

		case 'PROCESSO_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
			};
	
			case 'PROCESSO_UNIDADE_VINCULACAO_SELECT_LISTADO':
			return {
				...state,
				listaUnidadeVinculacao: action.payload.data
			};

		case 'PROCESSO_PARCEIRO_SELECT_LISTADO':
			return {
				...state,
				listaParceiro: action.payload.data
			};

		case 'PROCESSO_DOCUMENTO_LISTADO':
			return {
				...state,
				listaDocumento: (action.payload.data || []).filter(item => state.registro && item.id_processo == state.registro.id)
			};

		default:
			return state;
	}
}
