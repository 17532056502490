import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDate from '../../common/form/labelAndInputDate';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import FormatUtils from '../../common/formatUtils/FormatUtils';

import {
	setModoTela, initForm
} from './processoFinanceiroActions';

class ProcessoFinanceiroForm extends Component {

	state = {
		modoCadastroProduto: false,
		produto: null,
		modoCadastroUnidadeMedida: false,
		unidadeMedida: null
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<LabelAndInput
								name='tipo'
								value={this.props.formularioValues.entrada ? 'Receita' : 'Despesa'}
								onChange={() => {}}
								label='Tipo' placeholder='Informe o tipo'
								cols='12 12 4 4'
								readOnly={'readOnly'} />
						</Row>
						<Row>
							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição *' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor *' placeholder='Informe o valor'
								cols='12 12 4 4'
								casas={2}
								readOnly={readOnly} />
						</Row>
						<Row>
							<LabelAndInputDate
								name='data_pagamento'
								label='Data do pagamento' placeholder='Informe a data do pagamento'
								cols='12 6 4 4'
								readOnly={readOnly}
								value={this.props.formularioValues.data_pagamento}
								onChange={data => {
									this.props.initForm({
										...this.props.formularioValues,
										data_pagamento: data.target.value
									});
								}} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }
}

ProcessoFinanceiroForm = reduxForm({form: 'processoFinanceiroForm', destroyOnUnmount: false})(ProcessoFinanceiroForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('processoFinanceiroForm')(state),
	registro: state.processoFinanceiro.registro,
	listaTipo: state.processoFinanceiro.listaTipo,
	listaStatus: state.processoFinanceiro.listaStatus
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProcessoFinanceiroForm);
