import React from 'react';
import Grid from '../layout/grid';
import { SwatchesPicker } from 'react-color';

export default props => {
	return (
		<Grid cols={props.cols}>
			<div className='form-group'>
				<style>
					{
						`
						.bg-tipo {
							background-color: ${ props.color };
							width: 30px;
							height: 30px;
							border-radius: 50%;
							margin: 6px;
						}
						`
					}
				</style>
				<label>{props.label}</label>
				{
					//<input {...props.input} className='form-control' placeholder={props.placeholder} readOnly={props.readOnly} type={props.type} />
				}

				<div>
					<div className='bg-tipo'></div>
					<SwatchesPicker name={props.name}
						color={ props.color }
						onChangeComplete={ props.complete }
						/>
				</div>
			</div>
		</Grid>
	);
}
