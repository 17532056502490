import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CLIENTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('clienteForm', {
			...registro,
			data_expedicao_cr: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_expedicao_cr)),
			data_expedicao_gt: DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(registro.data_expedicao_gt))
		})
    ];
}

export function setFiltro(filtro) {
    return {
        type: 'CLIENTE_FILTRO',
        payload: filtro
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CLIENTE_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/cliente?
			id_clube=${getState().cliente.filtro.id_clube || null}
			&id_unidade_vinculacao=${getState().cliente.filtro.id_unidade_vinculacao || null}`)
		.then(resp => {
			dispatch({
				type: 'CLIENTE_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/cliente`, {
				...registro,
				cpf: FormatUtils.removerMascara(registro.cpf),
				cnpj: FormatUtils.removerMascara(registro.cnpj),
				telefone: FormatUtils.removerMascara(registro.telefone),
				cep: FormatUtils.removerMascara(registro.cep),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/cliente`, {
				...registro,
				cpf: FormatUtils.removerMascara(registro.cpf),
				cnpj: FormatUtils.removerMascara(registro.cnpj),
				telefone: FormatUtils.removerMascara(registro.telefone),
				cep: FormatUtils.removerMascara(registro.cep),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/cliente?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarSelect`);
    return {
        type: 'CLIENTE_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'CLIENTE_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaClube() {
    const request = axios.get(`${consts.API_URL}/clube/listarSelect`);
    return {
        type: 'CLIENTE_CLUBE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidadeVinculacao() {
    const request = axios.get(`${consts.API_URL}/unidadeVinculacao/listarSelect`);
    return {
        type: 'CLIENTE_UNIDADE_VINCULACAO_SELECT_LISTADO',
        payload: request
    };
}

export function initFormDocumento(registro = {}) {
    return [
        initialize('clienteDocumentoForm', registro)
    ];
}

export function getListaDocumento(id_cliente) {
    const request = axios.get(`${consts.API_URL}/clienteDocumento?id_cliente=${id_cliente}`);
    return {
        type: 'CLIENTE_DOCUMENTO_LISTADO',
        payload: request
    };
}

export function salvarDocumento(registro) {
    return (dispatch, getState) => {

		dispatch(setAguardando(true));

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/clienteDocumento`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaDocumento(registro.id_cliente));
				dispatch(setModoTela('listaDocumento', getState().cliente.registro));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/clienteDocumento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaDocumento(registro.id_cliente));
				dispatch(setModoTela('listaDocumento', getState().cliente.registro));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluirDocumento(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/clienteDocumento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaDocumento(registro.id_cliente));
			dispatch(setModoTela('listaDocumento', getState().cliente.registro));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
