import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setError, setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PROCESSO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('processoForm', {
			...registro
		})
    ];
}

export function setFiltro(filtro) {
    return {
        type: 'PROCESSO_FILTRO',
        payload: filtro
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'PROCESSO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {

	return (dispatch, getState) => {

		dispatch({
			type: 'PROCESSO_LISTADO',
			payload: { data: [] }
		});
		
		axios.get(`${consts.API_URL}/processo?
			id_cliente=${getState().processo.filtro.id_cliente || null}
			&id_status=${getState().processo.filtro.id_status || null}
			&id_tipo=${getState().processo.filtro.id_tipo || null}
			&status=${getState().processo.filtro.status.length > 0 ? getState().processo.filtro.status : null}
			&id_parceiro=${getState().auth.usuarioLogado ? getState().auth.usuarioLogado.id_parceiro || getState().processo.filtro.id_parceiro || null : 0}
			&id_unidade_vinculacao=${getState().processo.filtro.id_unidade_vinculacao || null}`)
			.then(resp => {
			dispatch({
				type: 'PROCESSO_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function getListaContasReceber() {

	return (dispatch, getState) => {

		dispatch({
			type: 'PROCESSO_CONTAS_RECEBER_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/processo/contasReceber?id_cliente=${getState().processo.filtro.id_cliente || null}`)
		.then(resp => {
			dispatch({
				type: 'PROCESSO_CONTAS_RECEBER_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/processo`, {
				...registro,
				data_previsao: registro.data_previsao,
				datahora_abertura: DateFormat.getDataHoraAtual(),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(initForm({
					...registro,
					...resp.data,
					datahora_abertura: DateFormat.getDataAtual()
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/processo`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/processo?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'PROCESSO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}
export function getListaUnidadeVinculacao() {
    const request = axios.get(`${consts.API_URL}/unidadeVinculacao/listarSelect`);
    return {
        type: 'PROCESSO_UNIDADE_VINCULACAO_SELECT_LISTADO',
        payload: request
    };
}
export function getListaParceiro() {
    const request = axios.get(`${consts.API_URL}/parceiro/listarSelect`);
    return {
        type: 'PROCESSO_PARCEIRO_SELECT_LISTADO',
        payload: request
    };
}

export function initFormDocumento(registro = {}) {
    return [
        initialize('processoDocumentoForm', registro)
    ];
}

export function getListaDocumento(id_cliente) {
    const request = axios.get(`${consts.API_URL}/clienteDocumento?id_cliente=${id_cliente}`);
    return {
        type: 'PROCESSO_DOCUMENTO_LISTADO',
        payload: request
    };
}

export function salvarDocumento(registro) {
    return (dispatch, getState) => {

		dispatch(setAguardando(true));

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/clienteDocumento`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaDocumento(registro.id_cliente));
				dispatch(setModoTela('listaDocumento', getState().processo.registro));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/clienteDocumento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaDocumento(registro.id_cliente));
				dispatch(setModoTela('listaDocumento', getState().processo.registro));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluirDocumento(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/clienteDocumento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaDocumento(registro.id_cliente));
			dispatch(setModoTela('listaDocumento', getState().processo.registro));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
