import React from 'react'
import Grid from '../layout/grid'

export default props => {
	return (
		<Grid cols={props.cols}>
	        <div className='form-group checkbox' style={{ paddingTop: 24 }}>
				<input {...props.input}
					 placeholder={props.placeholder}
					 readOnly={props.readOnly} type='checkbox' checked={props.input.value == true} />
	            <label htmlFor={props.name} style={{ fontWeight: 'bold', marginLeft: 4 }}>
					{props.label}
				</label>
	        </div>
	    </Grid>
	);
}
